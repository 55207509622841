import NavBarCardComponent from "./NavBarCardComponent";
import { useEffect } from "react";

function CardComponent({activeTab, cards, SetActiveCard, activeCard, children}) {

  useEffect(() => {
          window.scrollTo({ 
            top: 0, 
            left: 0, 
            behavior: 'instant'
          })
        }, [])

    return (
      <>
      {activeTab === 'menu' &&
        <div className="card-container">{
          cards?.length > 1 ? (
            <div className="card-header">
              {/* <div className="left-uotside-fake-card"></div> */}
              <div className="card-menu">
                <NavBarCardComponent 
                  items={cards}
                  setSelectedCardItem={SetActiveCard}
                  DefActiveCard={activeCard}
                />
              </div>
            </div>
          ) : null
        }
          <div className="card-body">{children}</div>
        </div>
      }
      </>
      
    );
  }
  
  export default CardComponent;
  