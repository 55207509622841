import React, { useEffect, useState } from "react";
import { useI18nContext } from "../i18n/context/context";
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import { useParams } from "react-router-dom";
import { ApiLibrary } from '../helpers/api/ApiLibrary';

function QrPage({ }) {
  const { language } = useI18nContext();
  const { restaurantCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async() => {
        setIsLoading(true);
        // let response = await new ApiLibrary(language).user.users.activateUser({id: userId, activationCode: activationKey}, language)
        setIsLoading(false);
        window.location.href = window.location.origin.concat('/').concat(restaurantCode)
    };
    
    if (restaurantCode) {
        fetchData();
    }

  }, [restaurantCode])

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
    </>    
  );
}

export default QrPage;
