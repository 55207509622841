import { Loader } from "rsuite";
import VideoPlayerComponent from "../VideoPlayerComponent";
import { useMemo } from "react";

function PictureComponent({ item, idx, OpenModal }) {

  const getImage = useMemo(() => {
    return (
      <>
        <div key={idx} className="item-picture-container pictures-item-picture-container">
            <div className="pictures-loader">
              <Loader size="md" className='dropzone-icon' />
            </div>
            {/* <MdFullscreen className="full-screen-icon" style={{pointerEvents: 'none'}} /> */}
              {(item.slice(-4) == ".jpg" || item.slice(-4) == ".png") &&
                <img
                  src={item}
                  className="item-img-style pictures-img-style cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    OpenModal(idx);
                  }}
                />
              }
              {!(item.slice(-4) == ".jpg" || item.slice(-4) == ".png") &&
                <div className="pictures-video-container" onMouseDown={(e) => {
                  OpenModal(idx);
                  e.stopPropagation();
                }}>
                  <VideoPlayerComponent
                    id="demo-player"
                    publicId={item}
                    className="item-video-style pictures-img-style cursor-pointer"
                    classNameContainer="pictures-video-container-inside"
                    autoplay={false}
                    playIfFocus={true}
                    currentTime={0}
                    muted

                    // id="demo-player"
                    // publicId={item}
                    // className="item-video-style pictures-img-style cursor-pointer"
                    // classNameContainer="pictures-video-container-inside"
                    // autoplay={false}
                    // playIfFocus={false}
                    // muted
                  />
                </div>
              }
          </div>
      </>
    ) 
  }, [item, idx])


    return (
      <>
        {getImage}
      </>
    );
  }
  
  export default PictureComponent;