import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { FaChevronLeft, FaChevronRight, FaPlus  } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import useScreenDetector from "../../../hooks/useScreenDetector";
import ModalEditComponent from "../../modal/ModalEditComponent";
import MenuGroupEditComponent from "./MenuGroupEditComponent";
import MenuItemsListAdminComponent from "./MenuItemsListAdminComponent";
import { useI18nContext } from "../../../i18n/context/context";
import { useAdminApiContext } from '../../../hooks/admin/context';
import { Sort } from '../../../helpers/arrays/sorting';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { HiTranslate } from "react-icons/hi";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import DictionaryEditModalComponent from "../DictionaryEditModalComponent";
import { useToaster, Message } from 'rsuite';

function NavBarGroupAdminComponent({ card, copy, extraData, userId, setRunningToken }) {
  const { translate, language } = useI18nContext();
  // const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  // const [selectedObject, setSelectedObject] = useState(null)
  const [canSelectItem, setCanSelectItems] = useState(true)
  const [itemsRefs, setItemsRefs] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const {
    state: { categories = [], selectedCategory, selectedCard } = {},
    fetchCategories,
    setSelectedCategory,
    addCategory,
    removeCategory,
    updateCategory,
    changeCategoriesOrder
  } = useAdminApiContext();
  // const setItem = useCallback((item) => {
  //   setCanSelectItems(true);
  //   if(canSelectItem){
  //     setSelectItem(item);
  //     let mGroups = groups;
  //     let idx = mGroups.map(x => x.name).indexOf(item)
  //     setSelectedObject(mGroups[idx])
  //   }
  // }, [groups, canSelectItem, selectedItem]);

  useEffect(() => {
    fetchCategories(card.id);
  }, [card.id])

  // useEffect(() => {
  //   if(!!items && (!selectedItem || !items.includes(selectedItem))){
  //     setItem(items[0])
  //   }
  //   let refds = items.reduce((acc, value) => {
  //     acc[value] = React.createRef();
  //     return acc;
  //   }, {});
  //   setItemsRefs(refds);
  // }, [items, canSelectItem, selectedItem])
   
  // useEffect(() =>{
  //   let sliderProperties = ourRef?.current?.children[0]?.getBoundingClientRect();
  //   let itemProperties = itemsRefs[selectedItem]?.current?.getBoundingClientRect();
  //   if(!!sliderProperties && !!itemProperties){
  //     if(itemProperties.x + itemProperties.width > sliderProperties.x + sliderProperties.width){
  //       let destPosition = ((itemProperties.x + itemProperties.width) - (sliderProperties.x + sliderProperties.width)) + ourRef.current.children[0].scrollLeft;
  //       ourRef.current.children[0].scrollTo({left: destPosition+30});
  //     }
  //     if(itemProperties.x < sliderProperties.x){
  //       let destPosition = ourRef.current.children[0].scrollLeft + itemProperties.x - sliderProperties.x;
  //       ourRef.current.children[0].scrollTo({left: destPosition-30});
  //     }
  //   }
  // }, [])

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  const setArrowsInfo = useCallback((sliderMenu) => {
    setShowLeftArrow(sliderMenu.scrollLeft > 1);
    setShowRightArrow(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1)
  }, [showLeftArrow, showRightArrow])

  const handleDragStart = useCallback((e) => {
    if (!ourRef.current) return;
    const sliderMenu = ourRef.current.children[0];
    const startX = e.pageX - sliderMenu.offsetLeft;
    const startY = e.pageY - sliderMenu.offsetTop;
    const scrollLeft = sliderMenu.scrollLeft;
    const scrollTop = sliderMenu.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.addEventListener("mouseup", mouseDownOutsideComponent);
  }, [ourRef, mouseCoords, isMouseDown]);

  const handleDragEnd = useCallback((e) => {
    setIsMouseDown(false);
    setCanSelectItems(true);
    document.removeEventListener("mouseup", mouseDownOutsideComponent, false);
    if (!ourRef.current) return;
  }, [ourRef, isMouseDown]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const myItems = Array.from(categories);
    const [reorderedItem] = myItems.splice(result.source.index, 1);
    myItems.splice(result.destination.index, 0, reorderedItem);
    // setOrders(myItems)
    await changeCategoriesOrder(selectedCard.id, myItems.map(({id}) => id))
  }
  
  const handleDrag = useCallback((e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const sliderMenu = ourRef.current.children[0];
    const x = e.pageX - sliderMenu.offsetLeft;
    const y = e.pageY - sliderMenu.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    sliderMenu.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    sliderMenu.scrollTop = mouseCoords.current.scrollTop - walkY;
    if(Math.abs(mouseCoords.current.scrollLeft - sliderMenu.scrollLeft) > 5){
      setCanSelectItems(false);
    }
  }, [isMouseDown, ourRef]);

  const handleWheel = useCallback((e) => {
    if (!ourRef.current || (ourRef.current.children[0].clientWidth >= ourRef.current.children[0].scrollWidth)) return;
    e.preventDefault()
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + (e.deltaY/5);
  }, [ourRef, showLeftArrow, showRightArrow]);

  const mouseDownOutsideComponent = useCallback((event) => {
    const el = ourRef?.current
    if (!el || el.contains(event.target)) {
      return;
    }
    handleDragEnd();
  }, [ourRef])

  const clickLeft = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft - 30;
    setArrowsInfo(sliderMenu)
  }

  const clickRight = () => {
    const sliderMenu = ourRef.current.children[0];
    sliderMenu.scrollLeft = sliderMenu.scrollLeft + 30;
    setArrowsInfo(sliderMenu)
  }

  const handleScroll = (e) => {
    setArrowsInfo(ourRef.current.children[0])
  }

  const { sizeScreen } = useScreenDetector();

  useEffect(() => {
    if(!ourRef)
      return;

    const sliderMenu = ourRef?.current.children[0];
    if(sliderMenu.scrollWidth - sliderMenu.scrollLeft > sliderMenu.clientWidth + 1){
      sliderMenu.addEventListener("wheel", handleWheel)
      sliderMenu.addEventListener("scroll", handleScroll);
      setArrowsInfo(sliderMenu);
    }
    else{
      sliderMenu.removeEventListener("wheel", handleWheel, false)
      sliderMenu.removeEventListener("scroll", handleScroll, false);
      setArrowsInfo(sliderMenu);
    }
  }, [ourRef, sizeScreen])

  const [newItem, setNewItem] = useState(false)

  const [isEditItem, setIsEditItem] = useState(false)

  const [editItemOldValue, setEditItemOldValue] = useState();

  const sortedCategories = useMemo(() => categories.sort((a, b) => Sort.numericAsc(a.order, b.order)), [categories]);

  const addNewItem = (val) => {
    if(val){
      addCategory(card.id, {name: val})
    }
    setNewItem(false);
    setIsEditItem(false);
    setEditItemOldValue()
  }

  const addNewGroup = (val) => {
    addCategory();

    // let mGroups = groups;
    // if(mGroups.length == 1 && mGroups[0].code=="specialEmptyGroup"){
    //     mGroups[0].id = 0;
    //     mGroups[0].name = val;
    //     mGroups[0].code = generateNewGroupCode(0);
    // }
    // else{
    //     let newId = Math.max(...mGroups.map(x => x.id)) + 1;
    //     let newItem = {
    //             id: newId,
    //             code: generateNewGroupCode(newId),
    //             language: language,
    //             name: val,
    //             items: []
    //         }
    //     mGroups.push(newItem);
    // }
    
    // setGroups(mGroups)
    // setItems(mGroups.map(x => x.name))
    // setItem(val)
  }

  const edit = (newVal) => {
    editGroup(editItemOldValue, {...editItemOldValue, name: newVal});
    setNewItem(false);
    setIsEditItem(false);
    setEditItemOldValue()
  }

  const addGroup = () => {
    setNewItem(true);
  }

  const editItem = (item) => {
    setEditItemOldValue(item)
    setIsEditItem(true)
  }

  const removeItem = () => {
    removeGroup(editItemOldValue)
  }

  const editGroup = (category, newValue) => {
    if(category.name == newValue.name){
      return;
    }
    updateCategory(category.id, newValue);
    // let mGroups = groups;
    // let idx = mGroups.map(x => x.name).indexOf(oldValue)
    // mGroups[idx].name = newValue
    // setGroups(mGroups)
    // setItems(mGroups.map(x => x.name))
    // if(selectedItem == oldValue){
    //   setItem(newValue)
    // }
  }

  const removeGroup = (category) => {
    removeCategory(category.id)
    // let mGroups = groups;
    // if(mGroups.length == 1){
    //   mGroups[0].id = 0
    //   mGroups[0].code = "specialEmptyGroup"
    //   mGroups[0].name = translate("specialEmptyGroup")
    // }
    // else{
    //   let idx = mGroups.map(x => x.name).indexOf(val)
    //   mGroups.splice(idx, 1);
    // }
      
    // setGroups(mGroups)
    // setItems(mGroups.map(x => x.name))
  }
  const [isSaving, setIsSaving] = useState(false)
  const toaster = useToaster();

  const [dictionaryItem, setDictionaryItem] = useState(null);

  const changeDictionary = async (dictionaryItem, val) => {
    dictionaryItem.text = val;
    setIsSaving(true)
    let result = await new ApiLibrary(language).dictionary.change(dictionaryItem)
    setIsSaving(false)
    dictionaryItem =result.data;
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("changedTranslation")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  return (
    <>
    {isSaving && 
        <LoaderDirectiveComponent />
      }
    {!!dictionaryItem &&
          <ModalEditComponent
            setOpen={() => setDictionaryItem(null)}
            model={dictionaryItem.text}
            setModelChanges={(val) => changeDictionary(dictionaryItem, val)}        
            hideLanguages
            isSimpleModel
            hideDeleteButton
            title={translate("translate")}
        >
          <DictionaryEditModalComponent />
        </ModalEditComponent>
        }
      <div className="header-menu-scroll-admin">
        {showLeftArrow &&
        (<div className="card-left-arrow" onClick={clickLeft}>
          <FaChevronLeft size={20} className="menu-left-arrow-nav-bar-scroll"/>
        </div>)
        }      
        <div
          ref={ourRef}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDrag}
          className="horizontal-menu-container scroll-horizontal"
        >
          <div id="sliderMenu" className="container-menu scroll-horizontal">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable direction='horizontal' droppableId="categories">
                {(provided) => (
                  <div style={{display: 'flex'}} {...provided.droppableProps} ref={provided.innerRef}>
                    {sortedCategories?.map((category, idx) => (
                      <Draggable key={category.id} draggableId={category.id} index={idx} isDragDisabled={extraData.defaultLang != language}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={idx} onClick={() => setSelectedCategory(category.id)}>
                            <div className={`horizontal-menu-item display-flex ${category.id == selectedCategory?.id ? "horizontal-menu-item-selected" : ""} ${!category.name ? "special-empty-element" : ""}`}>
                              <div ref={itemsRefs[category]}>
                                {category.name?.items.find(x => x.lang == language)?.text ?? translate('Brak kategorii')}
                              </div>
                              <>
                              {extraData.defaultLang == language ?
                                  <div className="cursor-pointer" onClick={(e) => { e.stopPropagation(); editItem(category)}} >
                                    <FaPencil className="margin-left-md" size={15} />
                                  </div>
                                  :
                                  <span className="translate-button" onClick={(e) => { e.stopPropagation(); setDictionaryItem(category.name?.items?.find(x => x.lang == language))}}>
                                    <HiTranslate size={16} />
                                  </span>
                              }
                              </> 
                            </div>
                          </div>)}
                      </Draggable>
                    ))}

                    {newItem && 
                      <>
                        <ModalEditComponent isSimpleModel title={translate("creationCategory")} setOpen={setNewItem} model={""} validModel={sortedCategories} setModelChanges={(val) => addNewItem(val)} hideDeleteButton hideLanguages >
                          <MenuGroupEditComponent />
                        </ModalEditComponent>
                      </>
                    }
                    
                    {isEditItem && 
                      <>
                        <ModalEditComponent isSimpleModel title={translate("editCategory")} setOpen={setIsEditItem} model={editItemOldValue.name.items.find(x => x.lang == language).text} validModel={sortedCategories.filter(({ id }) => id != editItemOldValue.id)} setModelChanges={edit} deleteItem={removeItem} hideLanguages >
                          <MenuGroupEditComponent />
                        </ModalEditComponent>
                      </>
                    }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {extraData.defaultLang == language &&
              <div className="cursor-pointer horizontal-menu-item" onClick={() => addGroup()}>
                <FaPlus size={15} className=""/>
              </div>
            }
          </div>
          {showRightArrow && 
            <div className="card-right-arrow" onClick={clickRight}>
              <FaChevronRight size={20} className="menu-right-arrow-nav-bar-scroll"/>
            </div>
          }
        </div>
      </div>
      {!!selectedCategory && <MenuItemsListAdminComponent extraData={extraData} setItems={setItems} copy={copy} userId={userId} setRunningToken={setRunningToken} />}
    </>
    
  );
}

export default NavBarGroupAdminComponent;
