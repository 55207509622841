import React, { useState } from "react";
import { Input, Button, useToaster, Message } from 'rsuite';
import { useI18nContext } from "../i18n/context/context";
import LogoBackComponent from "../components/base/top/LogoBackComponent";
import LanguageComponent from "../components/base/top/LanguageComponent";
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import { ApiLibrary } from "../helpers/api/ApiLibrary";

function EditDemoPage({ }) {
    const { translate, language } = useI18nContext();
    const [model, setModel] = useState({verificationKey: '', baseUrl: window.location.origin})
    const [mail, setMail] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const toaster = useToaster();
  
    const generateDemo = async() => {
        setIsLoading(true);
        let response = await new ApiLibrary(language).restaurants.general.generateDemo(model, language)
        setIsLoading(false);
        if(response.data){
            window.location.href = response.data
        }
    }

    const askForDemoCode = async() => {
        const strings = [translate("verificationCode"), translate("copy"), translate("or"), translate("click")]
        setIsLoading(true);
        let response = await new ApiLibrary(language).restaurants.general.askForDemoCode({mail: mail, baseUrl: window.location.origin, strings: strings}, language)
        setIsLoading(false);
        if(response.data){
            toaster.push(
                <Message showIcon type="success" closable>
                  {translate("mailSent")}
                </Message>, 
                { placement: "topEnd", duration: 5000 }
              )
        }
    }

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent />
              </div>
              <div className="display-flex">
              </div>
              <LanguageComponent />
            </div>
          </div>
          <div className="admin-header-underline" />
          <div className="success-page-container">
            <div className="edit-demo-info">
                {translate("editDemoInfo")}
            </div>
            <div className="success-page-inside">
              <div className="edit-demo-container">
                <div className="edit-demo-generated-container">
                    <Input id="verificationKey" className={`   ${!!model?.verificationKey ? "sd-selected" : ""}`}
                    placeholder={translate("verificationKey")}
                    defaultValue={model?.verificationKey}
                    onChange={(val, e) => {
                        let thisModel = {...model};
                        thisModel.verificationKey = val;
                        setModel(thisModel);
                    }}
                    />
                    <Button
                        className="edit-demo-generated-button"
                        color="green"
                        appearance="primary"
                        onClick={() => {generateDemo()}}
                        >
                        {translate('generateEditDemo')}
                    </Button>
                </div>
                <div className="edit-demo-text-container">
                  {translate('or')}
                </div>
                <div className="edit-demo-generated-container">
                    <Input id="mail" className={` admin-basic-edit-item-field-text  ${!!mail ? "sd-selected" : ""}`}
                    placeholder={translate("mail")}
                    defaultValue={mail}
                    onChange={(val, e) => {
                        setMail(val);
                    }}
                    />
                    <Button
                        color="green"
                        appearance="primary"
                        className="edit-demo-generated-button"
                        onClick={() => {askForDemoCode()}}
                        >
                        {translate('askForVerificationCode')}
                    </Button>
                </div>
              </div>
            </div>
          </div>
    </>    
  );
}

export default EditDemoPage;
