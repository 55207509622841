/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import "../css/dish.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Favicon from "react-favicon";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import DishItemsListPerRestaurantComponent from "../components/dish/DishItemsListPerRestaurantComponent";
import DishItemsListPerRestaurantMenuComponent from "../components/dish/DishItemsListPerRestaurantMenuComponent";
import LanguageComponent from "../components/base/top/LanguageComponent";
import FiltersRestaurantComponent from "../components/base/left/FiltersRestaurantComponent";
import FilterHidingComponent from "../components/base/filter/FilterHidingComponent";
import SearchComponent from "../components/base/center/SearchComponent";
import RestaurantDetailsComponent from "../components/restaurant/RestaurantDetailsComponent";
import RestaurantTabComponent from "../components/restaurant/RestaurantTabComponent";
import HorizontalScrollingMenuComponent from "../components/base/center/HorizontalScrollingMenuComponent";
import NavBarScrollComponent from "../components/base/head/NavBarScrollComponent";
import SortComponent from "../components/base/center/SortComponent";
import useScreenDetector from "../hooks/useScreenDetector";
import LogoBackComponent from "../components/base/top/LogoBackComponent";
import { IconButton } from "rsuite";
import { RiMenuUnfoldLine } from 'react-icons/ri';
import { TbFilterSearch } from "react-icons/tb";
import DescriptionComponent from '../components/base/center/DescriptionComponent';
import PicturesComponent from '../components/base/center/PicturesComponent';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CardComponent from "../components/base/center/CardComponent";
import { useDishesSort, DishesSortByType } from '../hooks/dishes/useDishesSort';
import { useDishesFilter } from '../hooks/dishes/useDishesFilter';
import { useDishesSearch } from '../hooks/dishes/useDishesSearch';
import { useI18nContext } from '../i18n/context/context';
import RestaurantStartComponent from "../components/restaurant/RestaurantStartComponent";
import ModalPromotionComponent from "../components/modal/ModalPromotionComponent";
import { FaPercent } from "react-icons/fa";
import dishesDatas from '../datas/dishes2.json';
import colors from '../datas/colors.json'
import { ApiLibrary } from '../helpers/api/ApiLibrary';
import { Sort } from '../helpers/arrays/sorting';
import ModalMediaComponent from "../components/modal/ModalMediaComponent";

const FilterButtonAppearance = {
  DEFAULT: 'default',
  ACTIVE: 'primary'
};

function RestaurantDefPage({ OpenModal }) {
  const { translate, language, setRestaurantLanguages } = useI18nContext();
  const [showStart, setShowStart] = useState(true);
  const [openPromotionModal, setOpenPromotionModal] = useState(); 
  const [showPromotionButton] = useState(true);

  const [filtersList, setFiltersList] = useState(null);
  const [faviconUrl, setFaviconUrl] = useState(window.location.origin.concat("/sefoo.svg"))
  const { restaurantCode } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [rawDishesData, setRawDishesData] = useState(null);
  const { sort, sortBy, setSortBy, sortByOptions } = useDishesSort();
  const { filter, filters, isFilterActive, setFilters, resetFilters } = useDishesFilter('PLN');
  const { search, searchBy, setSearchBy } = useDishesSearch();
  const filterButtonAppearance = useMemo(() => (
    isFilterActive ? FilterButtonAppearance.ACTIVE : FilterButtonAppearance.DEFAULT
  ), [isFilterActive]);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const calculateItems = useCallback((items) => {
    if (!items) {
      return;
    }

    return items.reduce((categorizedDishes, dish) => {
      const category = dish.category?.text ?? translate('noDishType');

      if (!categorizedDishes[category]) {
        return {
          ...categorizedDishes,
          [category]: [dish]
        }
      }

      return {
        ...categorizedDishes,
        [category]: [
          ...categorizedDishes[category],
          dish
        ]
      }
    }, {});
  }, [language]);

  useEffect(() => {
    const fetchData = async() => {
      const response = await new ApiLibrary(language).restaurants.general.getByCode(restaurantCode);

      if (!response.data) {
        window.location.href = window.location.origin;
        return;
      }
      setRestaurantLanguages(response?.data?.extraConfig.langs.concat(response?.data?.defaultLang));

      let root = document.querySelector(':root');
      let firstColor = colors.sefoo.firstColor;
      let secondColor = colors.sefoo.secondColor;
      let thirdColor = colors.sefoo.thirdColor;
      let fourthColor = colors.sefoo.fourthColor;
      let fifthColor = colors.sefoo.fifthColor;
      let sixthColor = colors.sefoo.sixthColor;
      if(Object.keys(colors).includes(response.data.color)){
        firstColor = colors[response.data.color].firstColor;
        secondColor = colors[response.data.color].secondColor;
        thirdColor = colors[response.data.color].thirdColor;
        fourthColor = colors[response.data.color].fourthColor;
        fifthColor = colors[response.data.color].fifthColor;
        sixthColor = colors[response.data.color].sixthColor;
      }

      root.style.setProperty('--sefooColor', firstColor);
      root.style.setProperty('--secondSefooColor', secondColor);
      root.style.setProperty('--rs-btn-primary-bg', firstColor);
      root.style.setProperty('--rs-btn-primary-hover-bg', secondColor);
      root.style.setProperty('--rs-menuitem-active-text', secondColor);
      root.style.setProperty('--rs-dropdown-item-text-active', secondColor);
      root.style.setProperty('--rs-btn-primary-active-bg', secondColor);
      root.style.setProperty('--rs-btn-ghost-text', firstColor);
      root.style.setProperty('--rs-btn-ghost-border', secondColor);
      root.style.setProperty('--rs-btn-ghost-hover-text', secondColor);
      root.style.setProperty('--rs-btn-ghost-hover-border', secondColor);
      root.style.setProperty('--rs-toggle-checked-hover-bg', firstColor);
      root.style.setProperty('--rs-input-focus-border', firstColor);
      root.style.setProperty('--secondBackgroundColor', thirdColor);
      root.style.setProperty('--rs-dropdown-item-bg-active', thirdColor);
      root.style.setProperty('--rs-listbox-option-hover-bg', fourthColor);
      root.style.setProperty('--rs-menuitem-active-bg', fourthColor);
      root.style.setProperty('--rs-message-info-bg', sixthColor);
      root.style.setProperty('--rs-message-info-icon', firstColor);
      root.style.setProperty('--rs-color-focus-ring', fifthColor);
      document.title = response.data.restaurantName;
      setFaviconUrl(window.location.origin.concat("/").concat(response.data.color).concat('.svg'))
      

      setRestaurant(response.data);
      setShowStart(!!response.data.poster)
      setOpenPromotionModal(!!response.data.annoucements?.length)

      if (!selectedMenu || !response.data.menus[0]?.catalogs.map(x => x.name).some(x => x == selectedMenu?.name)) {
        setSelectedMenu(response.data.menus[0]?.catalogs.find(x => x.id == selectedMenu?.id) ?? response.data.menus[0]?.catalogs[0])
      }
    };

    if (restaurantCode) {
      fetchData();
    }
  }, [restaurantCode, language]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async() => {
      setRawDishesData(null);
      setFiltersList(null);

      const [dishesResponse, filtersResponse] = await Promise.all([
        new ApiLibrary(language).menu.cards.getFlatDishesList(selectedMenu.id, {signal: abortController.signal}),
        new ApiLibrary(language).menu.cards.getFiltersOfCard(selectedMenu.id, {signal: abortController.signal})
      ]);

      if (dishesResponse.data) {
        setRawDishesData(dishesResponse.data);
      }

      if (filtersResponse.data) {
        setFiltersList(filtersResponse.data);
      }
    };

    if (selectedMenu && !showStart) {
      fetchData();
    }

    return () => {
      abortController.abort('Aborted');
    }
  }, [selectedMenu, language, showStart])

  const { sizeScreen } = useScreenDetector();
  const [tabs, setTabs] = useState([]);
  const [activeTab, SetActiveTab] = useState(null);

  const setActiveTabFunction = (val) => {
    SetActiveTab(val)
  }


  useEffect(() => {
    // setShowPromotionButton(restaurant.showPromotion)
    // setShowPromotionButton(true)
    // setOpenPromotionModal(true)
    // setShowStart(true)

    const mTabs = ['menu'];
    const details = document.getElementById('restaurantDetailsFilters');
    if (!!details && getComputedStyle(details).display === 'none') {
      mTabs.push('basicData');
    }

    if(!!restaurant && !!restaurant.description){
      mTabs.push('description');
    }

    if (!!restaurant && !!restaurant.medias && !!restaurant.medias.visible) {
      mTabs.push('pictures');
    }

    setTabs(mTabs);
    if (!mTabs.includes(activeTab) || !activeTab) {
      setActiveTabFunction(mTabs[0]);
    }
  }, [sizeScreen, restaurant])


  const prepareData = useCallback((filters, searchBy, sortBy) => {
    if (!rawDishesData) {
      return;
    }

    let sortRes = rawDishesData;
    if (rawDishesData) {
      if (filters) {
        sortRes = filter(sortRes, filters);
      }
      if (searchBy) {
        sortRes = search(sortRes, searchBy);
      }
      if (sortBy) {
        sortRes = sort(sortRes, sortBy, filters);
      }
    }

    return sortRes;
  }, [rawDishesData]);

  const dishes = useMemo(() => {
    return prepareData(filters, searchBy, sortBy);
  }, [rawDishesData, sortBy, searchBy, filters]);

  const categorizedDishes = useMemo(() => {
    return calculateItems(dishes);
  }, [dishes, searchBy, filters]);

  const [menuItemsRefs, setMenuItemsRefs] = useState([]);
  const GetItemsRefs = (refs) => {
    setMenuItemsRefs(refs);
  };

  const handleCardChange = (menuName) => {
    if (menuName === selectedMenu.name) {
      return;
    }
    setSelectedMenu(restaurant?.menus[0]?.catalogs.find(({ name }) => menuName === name));
    setRawDishesData(null);
    resetFilters('PLN');
  }

  const sortedCards = useMemo(() => (
    restaurant?.menus[0].catalogs.sort((a, b) => Sort.numericAsc(a.order, b.order)) ?? []
  ), [restaurant?.menus]);

  return (
    <div>
      <Favicon url={faviconUrl} />
      {
        showStart && 
        <>
        {!!restaurant?.poster?.content &&
          <RestaurantStartComponent content={restaurant?.poster.content} onContinue={() => setShowStart(false)} />
        }
        {!!restaurant?.poster?.media &&
          <ModalMediaComponent closeAfterClick SetModalState={() => setShowStart(false)} modalState={showStart} photos={[restaurant?.poster.media]} index={0} hideCloseButton />
        }
        </>
      }
      <div>
          <div>
          {!showStart &&
            <ModalPromotionComponent
              promotion={restaurant?.annoucements?.[0]}
              onClose={() => setOpenPromotionModal(false)}
              open={openPromotionModal}
            />
          }
        </div>
        <div id="header"
          className={
            filterOpen ? 'background-outside-modal-style disabled-element' : ''
          }>
          <div className="col-12 margin-bottom-sm header-flex-style main-header-style">
            <div className="margin-top-sx">
              {!!restaurant && <LogoBackComponent color={restaurant?.extraConfig?.color} 
              // pathBack={window.location.origin}
              />}
            </div>
            <div>
              <LanguageComponent langs={[restaurant?.defaultLang].concat(restaurant?.extraConfig?.langs)} />
            </div>
          </div> 

          { !!tabs &&
            <div className="col-12 display-flex fix-margin-under header-tabs">
              <div className="left-header-style">
                <div className="fake-field-class"></div>
              </div>
              <div className="center-header-style">
                <NavBarScrollComponent
                  items={tabs}
                  setSelectedMenuItem={setActiveTabFunction}
                  translate={translate}
                  DefActiveTab={activeTab}
                  canRevers
                />
              </div>
            </div>
          }
        </div>
        <div id="restaurantBody" className="col-12 main margin-bottom-md">
          {(activeTab === 'description' || activeTab === 'pictures' || activeTab === 'basicData') && <div className="left-center-side">
            {
              activeTab === 'description' && <DescriptionComponent content={restaurant.description.content} />
            }
            {
              activeTab === 'pictures' && <PicturesComponent items={restaurant.medias.items.map(({url}) => url)} OpenModal={OpenModal} />
            }
            {activeTab === 'basicData' && (
                <div className="margin-top-mx padding-left-md padding-right-md">
                  {!!restaurant && (
                    <RestaurantTabComponent
                      translate={translate}
                      restaurant={restaurant}
                      OpenModal={OpenModal}
                    />
                  )}
                </div>
              )}
          </div>}
          { activeTab === 'menu' && 

          <CardComponent activeTab={activeTab}
                          cards={sortedCards.map(({ name }) => name)}
                          SetActiveCard={handleCardChange}
                          activeCard={selectedMenu?.name}>
            { activeTab !== 'description' && activeTab !== 'pictures' && activeTab !== 'basicData' &&
            <div id="restaurantStaticFilters" className="left-filter-style padding-right-md">
              {!!filtersList && activeTab === 'menu' && (
                <FiltersRestaurantComponent
                  isInCard={restaurant?.menus?.length > 0}
                  translate={translate}
                  SetFilterData={setFilters}
                  config={filtersList}
                  filters={filters}
                />
              )}
            </div>}
          { !!filtersList &&
            (<div className="filter-menu-container-style">
              <FilterHidingComponent
                isOpen={filterOpen}
                translate={translate}
                SetFilterData={setFilters}
                config={filtersList}
                filters={filters}
                filterButtonAppearance={filterButtonAppearance}
                SetFilterClose={() => setFilterOpen(false)}
              />
            </div>
            )}
            <div
              id="restaurantCenter"
              className={cx('center-side-style', {
                'width-none': activeTab === 'description' || activeTab === 'pictures' || activeTab === 'basicData'
              })}
            >
              {
                  <div className="">
                    <div id="headerManuSearch" className={`header-style  ${restaurant?.menus?.length > 1 ? 'sticky-position-style': 'sticky-zero-position-style'}`}>
                      <div className="display-flex">
                        <div id="hiddingFilters" className="padding-left-md">
                          <IconButton
                            circle
                            icon={<TbFilterSearch  size={15}/>}
                            appearance={filterButtonAppearance}
                            onClick={() => setFilterOpen(!filterOpen)}
                          />
                        </div>
                        {
                          <div className="padding-left-md">
                            <SortComponent
                              data={Object.values(sortByOptions)}
                              SetSortFunction={setSortBy}
                              defSelectedItemKey={sortBy}
                            />
                          </div>
                        }
                        <div className="search-style">
                          <SearchComponent
                            translate={translate}
                            SetSearchFunction={setSearchBy}
                            delay={0}
                          />
                        </div>
                      </div>
                      {!!categorizedDishes && Object.keys(categorizedDishes).length > 1 && sortBy === DishesSortByType.GROUP_SORT && (
                        <div className="margin-top-sm">
                          <HorizontalScrollingMenuComponent
                            items={Object.keys(categorizedDishes)}
                            menuItemsRefs={menuItemsRefs}
                            isInCard={restaurant?.menus?.length > 0}
                          />
                        </div>
                      )}
                    </div>

                    {sortBy !== DishesSortByType.GROUP_SORT
                      ? <div className="margin-top-sx">
                        <DishItemsListPerRestaurantComponent
                          translate={translate}
                          OpenModal={OpenModal}
                          dishes={dishes}
                        />
                      </div>
                      : <div className="margin-top-sm"><DishItemsListPerRestaurantMenuComponent
                        translate={translate}
                        OpenModal={OpenModal}
                        dishes={categorizedDishes}
                        GetItemsRefs={GetItemsRefs}
                      /></div>
                    }
                  </div>
              }
            </div>
          </CardComponent>

          }
          <div id="restaurantDetailsFilters" className="right-side-style">
            <div className="sticky-zero-position-style">
              {!!restaurant && (
                <RestaurantDetailsComponent
                  translate={translate}
                  restaurant={restaurant}
                  OpenModal={OpenModal}
                />
              )}
            </div>
          </div>
        </div>
        {!!restaurant && !!restaurant.annoucements?.length && activeTab === 'menu' && <div id="promotionButton" className="go-top-icon-style">
                <IconButton
                  icon={<FaPercent className="go-top-icon-inside-button" />}
                  appearance="primary"
                  circle
                  onClick={() => setOpenPromotionModal(true)}
                />
              </div>}
      </div>
      
    </div>
  );
}

RestaurantDefPage.propTypes = {
  OpenModal: PropTypes.func.isRequired,
  setShowGoTopButton: PropTypes.func.isRequired
};

export default RestaurantDefPage;
