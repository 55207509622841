import React, { useEffect, useState } from "react";
import { useI18nContext } from "../../../i18n/context/context";
import { Tree } from 'rsuite';

function CopyItemComponent({ model, setChanges, setCanSave, validModel, copyElementModel, items }) {
  const { translate } = useI18nContext();

  const [data, setData] = useState([])
  const [result, setResult] = useState([])
  const [selectedValue, setSelectedValue] = useState("")
  const [expandedValue, setExpandedValue] = useState([])
  const [myCanSave, setMyCanSave] = useState(false)

  useEffect(() => {
    if(!!items){
        let myData = items.map(x => ({
            expand: true,
            disabled: true,
            label: x.name,
            value: x.code,
            visible: true,
            children: x.cards.map( y => ({
                expand: true,
                label: y.name,
                value: y.code,
                visible: true,
                children: y.groups.map( z => ({
                    expand: true,
                    label: z.name,
                    value: z.code,
                    visible: true
                }))
            }))
        
        }))
        setData(myData)
    }
  }, [items])

  useEffect(() => {

  }, [copyElementModel])

  useEffect(() => {
    setCanSave(myCanSave)
  }, [myCanSave])

  const onSelect = (item, value, e) => {
    if(!item.children){
        setSelectedValue(value)
    }
    else{
        let myExpandedValue = expandedValue;
        if(myExpandedValue.filter(x => x == value).length == 0){
            myExpandedValue.push(value)
        }
        else{
            let idx = myExpandedValue.indexOf(value)
            myExpandedValue.splice(idx, 1);
        }
        setExpandedValue(myExpandedValue)
    }
  }

  return (
    <div className="edit-item-style">
      <div className="edit-item-field">
        {!!data && 
            <Tree defaultExpandAll cascade data={data} value={selectedValue} onSelect={onSelect} />

            // <TreePicker defaultExpandAll data={data} open searchable={false} value={selectedValue} onSelect={onSelect} />
        }
      </div>
    </div>
  );
}

export default CopyItemComponent;
