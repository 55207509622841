import "./Modal.css";
import { useState, useEffect, useCallback, useRef } from "react";
import Modal from '@mui/material/Modal';
import { IoClose } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import VideoPlayerComponent from "../base/VideoPlayerComponent";

function ModalDefComponent({ SetModalState, photo, modalState, photos, index, hideCloseButton }) {
  const [open, setOpen] = useState(modalState);
  const [idx, setIdx] = useState(index);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [videoRefCurrent, setVideoRefCurrent] = useState(null);
  const ourRef = useRef(null);
  const insideRef = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    SetModalState(false);
  }

  // useEffect(() => {
  //   document.addEventListener('click', clickOutside);
  //   return () => {
  //     document.removeEventListener('click', clickOutside);
  //   }
  // }, [clickOutside])

  const clickOutside = useCallback((event) => {
    const el = insideRef?.current ?? videoRefCurrent
    if (!el || el.contains(event.target)) {
        return
    }
    handleClose()
  }, [insideRef, videoRefCurrent])

  useEffect(() => {
    if(!isTouchable()){
      window.addEventListener('click', clickOutside);
      return () => {
        window.removeEventListener('click', clickOutside);
      }
    }
  }, [clickOutside]);


  const isTouchable = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  useEffect(() => {
    if(isTouchable()){
      window.history.pushState({}, '', window.location.href);
      const handlePopState = (event) => {
        handleClose();
        window.history.replaceState({}, '', window.location.href);
      }; 
      window.addEventListener('popstate', handlePopState); 
      return () => { window.removeEventListener('popstate', handlePopState); };
    }
  }, [])

  const clieckLeft = (e) => {
    e.stopPropagation();
    setIdx(idx-1);
  }

  const clieckRight = (e) => {
    e.stopPropagation();
    setIdx(idx+1);
  }


  const handleDragStart = useCallback((e) => {
    if(ourRef?.current){

    }
    setIsMouseDown(true)
  }, [ourRef, isMouseDown])

  const handleDragEnd = (e) => {
    if(ourRef?.current){
      
    }
    setIsMouseDown(false)
  }

  const handleDrag = useCallback((e) => {
    if(ourRef?.current && isMouseDown){
    }
  }, [ourRef, isMouseDown])

  const keyDownFunction = useCallback((e) => {
    if(e.code == "ArrowLeft" && idx > 0){
      setIdx(idx-1);
    }
    if(e.code == "ArrowRight" && idx < photos.length-1){
      setIdx(idx+1);
    }
  }, [idx])

  useEffect(() => {
    if(!!photos){
      document.addEventListener("onmouseup", handleDragEnd, false);
      document.addEventListener("ontouchend", handleDragEnd, false);
      document.addEventListener("keydown", keyDownFunction, false);
      return () => {
        document.removeEventListener("onmouseup", handleDragEnd, false);
        document.removeEventListener("ontouchend", handleDragEnd, false);
        document.removeEventListener("keydown", keyDownFunction, false);
      }
    }
  }, [photos, idx])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {!!photos && 
          <div className="modalBackground">
            <div className="modalContainer" 
              ref={ourRef}
              // onMouseDown={handleDragStart}
              // onMouseUp={handleDragEnd}
              // onMouseMove={handleDrag}
              // onTouchStart={handleDragStart} 
              // onTouchMove={handleDrag} 
              >
                {!hideCloseButton &&
              <>
              {isTouchable() &&
                      <FaArrowLeft
                                className="closeButtonListPhone"
                                onClick={handleClose}
                              />
                    }
                </> 
              }
            <div className="body">
              {idx > 0 && 
                <div className="photos-arrow">
                  <FaChevronLeft size={40} className="photo-left-arrow" onClick={clieckLeft}/>
                </div>
              }
              {(photos[idx].slice(-4) == ".jpg" || photos[idx].slice(-4) == ".png") &&
                        <img
                          ref={insideRef}
                          src={photos[idx]}
                          className="modal-picture-style"
                        />
                      }
                      {!(photos[idx].slice(-4) == ".jpg" || photos[idx].slice(-4) == ".png") &&
                        <div className="" onClick={(e) => {}}>
                          <VideoPlayerComponent
                            // ref={insideRef}
                            id="demo-player"
                            publicId={photos[idx]}
                            autoplay={true}
                            muted={true}
                            setVideoRef={(ref) => {setVideoRefCurrent(ref?.current)}}
                            className="modal-video-style-inside"
                            classNameContainer="modal-video-style"
                          />
                        </div>
                      }
              
              {idx < photos.length-1 && 
                <div className="photos-arrow">
                  <FaChevronRight size={40} className="photo-right-arrow" onClick={clieckRight} />
                </div>
              }
              {!hideCloseButton &&
              <>
                    {!isTouchable() &&
                      <IoClose
                                className="closeButtonList"
                                onClick={handleClose}
                              />
                    }
                </> 
              }
            </div>
          </div>
        </div>
        }
      </Modal>
    </div>
  );
}

export default ModalDefComponent;
