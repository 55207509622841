import React, { useCallback, useEffect, useState } from "react";
import { Input, Button, useToaster, Message } from 'rsuite';
import { useCookies } from 'react-cookie';
import { useI18nContext } from "../../i18n/context/context";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import LogoBackComponent from "../../components/base/top/LogoBackComponent";
import LanguageComponent from "../../components/base/top/LanguageComponent";
import ActivationModalComponent from "../../components/modal/ActivationModalComponent";
import DialogModalComponent from "../../components/modal/DialogModalComponent";

function ResetPasswordPage({ }) {
  const { translate, language } = useI18nContext();
  const toaster = useToaster();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null)
  const [wrongInfo, setWrongInfo] = useState(null)
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);

  useEffect(() => {
    const verify = async () => {
      try {
        const myCookie = cookies['sefooToken'];

        if(!!myCookie?.token){
          setIsLoading(true)
          const tokenResponse = await new ApiLibrary().user.users.verifyToken(myCookie?.userId, myCookie?.token);
          setIsLoading(false)
          if (tokenResponse?.data?.responseValue) {
            window.location.href = window.location.origin.concat('/user/').concat(myCookie.userId);
          }
          else{
            deleteCookie('sefooToken', { path: '/' });  
          }
        }        
      }
      catch(e){
        deleteCookie('sefooToken', { path: '/' });
      }
    }
    
    verify()

    setModel({
      mail: null,
      password: null,
      confirmPassword: null
    })
  }, [])

  const handleSubmit = async () => {
    const strings = [translate("verificationCode"), translate("copy"), translate("or"), translate("click")]
    setIsLoading(true);
    const response = await new ApiLibrary(language).user.users.resetPasswordAsk({...model, baseUrl: window.location.origin, strings: strings}, language);
    setIsLoading(false);
    if (!!response?.data?.userId) {
        setUserId(response?.data?.userId);
        toaster.push(
          <Message showIcon type="success" closable>
            {translate("changePasswordSuccess")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
    }
    else{
        setWrongInfo(translate(response?.data?.responseCode))
    }
  }

  const answereActivationUser = async (val) => {
    setUserId(null)
    if(val){
        setIsLoading(true);
        const response = await new ApiLibrary(language).user.users.resetPasswordAnswere(model, language);
        setIsLoading(false);
        window.location.href = window.location.origin.concat('/').concat('success');
    }
  }

  const keyDownFunction = useCallback((e) => {
    if(e.code == "Enter" && !userId && !wrongInfo && !isLoading && !!model?.mail && model?.password && model?.confirmPassword && model?.confirmPassword == model?.password && 
        String(model?.password).match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|]).{8,}$/)){
      handleSubmit()
    }
  }, [model, userId, wrongInfo, isLoading])

  useEffect(() => {
    document.addEventListener("keydown", keyDownFunction, false);
    return () => {
      document.removeEventListener("keydown", keyDownFunction, false);
    }
  }, [model, userId, wrongInfo, isLoading])

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {!!userId &&
          <ActivationModalComponent userId={userId} blockCloseOutside={true} onCloseWithAnswere={answereActivationUser} open={!!userId} text={translate("activationUserInfo")} />
        }
        {!!wrongInfo &&
          <DialogModalComponent blockCloseOutside open={!!wrongInfo} onClose={() => setWrongInfo(null)} text={wrongInfo} />
        }
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent pathBack={window.location.origin.concat('/login')} />
              </div>
              <div className="display-flex">
              </div>
              <LanguageComponent />
            </div>
          </div>
          <div className="admin-header-underline" />
          <div className="user-login-register-title">
            {translate('resetPassword')}
          </div>
        <div className="admin-user-container">
          {!!model &&
            <div id="registerContainer" className="admin-basic-data-inside-container">
            <div className="display-flex">
                <div className="admin-basic-label ">
                    {translate("mail")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                <Input id="mail" className={` admin-basic-edit-item-field-text  ${!!model?.mail ? "sd-selected" : ""}`}
                    placeholder={translate("mail")}
                    defaultValue={model?.mail}
                    onChange={(val, e) => {
                        let thisModel = {...model};
                        thisModel.mail = val;
                        setModel(thisModel);
                    }}
                    />
                </div>
            </div>
            <div className="display-flex margin-top-xl">
                <div className="admin-basic-label">
                    {translate("newPassword")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                    <Input id="password" type="password" className={` admin-basic-edit-item-field-text
                                                                    ${!!model?.password && !String(model?.password).match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|]).{8,}$/) ? "edit-input-error" : ""}
                                                                     ${!!model?.password ? "sd-selected" : ""}`}
                        placeholder={translate("newPassword")}
                        defaultValue={model?.password}
                        onChange={(val, e) => {
                            let thisModel = {...model};
                            thisModel.password = val;
                            setModel(thisModel);
                        }}
                    />
                    <div className={`input-text-format-info ${!String(model?.password).match(/^.{8,}$/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatMinEightSign")}
                            </div>
                            <div className={`input-text-format-info ${!model?.password || !String(model?.password).match(/^(?=.*?[a-z])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatSmallLetter")}
                            </div>
                            <div className={`input-text-format-info ${!String(model?.password).match(/^(?=.*?[A-Z])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatBigLetter")}
                            </div>
                            <div className={`input-text-format-info ${!String(model?.password).match(/^(?=.*?[0-9])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatDigit")}
                            </div>
                            <div className={`input-text-format-info ${!String(model?.password).match(/^(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|])/) ? "input-text-format-info-wrong" : ""}`}>
                                {translate("passwordFormatSpecialSign")}
                            </div>
                </div>
            </div>
            <div className="display-flex margin-top-xl">
                <div className="admin-basic-label">
                    {translate("confirmPassword")}
                </div>
                <div className="admin-basic-edit-item-field-text  ">
                    <Input id="confirmPassword" type="password" className={` admin-basic-edit-item-field-text  
                                                                            ${!!model?.confirmPassword && model?.password != model?.confirmPassword ? "edit-input-error" : ""}
                                                                            ${!!model?.confirmPassword ? "sd-selected" : ""}`}
                        placeholder={translate("confirmPassword")}
                        defaultValue={model?.confirmPassword}
                        onChange={(val, e) => {
                            let thisModel = {...model};
                            thisModel.confirmPassword = val;
                            setModel(thisModel);
                        }}
                    />
                </div>
            </div>
        </div>
          }
            <div className='save-button-container'>
            <Button
                color="green"
                appearance="primary"
                onClick={() => handleSubmit()}
                disabled={!model?.mail || !model?.password || !model?.confirmPassword || model?.confirmPassword != model?.password || 
                    !String(model?.password).match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()\-_=+\[\]{};:'",.<>?/\\|]).{8,}$/)
                }
            >
                {translate('save')}
            </Button>
            </div>
        </div>
    </>    
  );
}

export default ResetPasswordPage;
