import { BaseApi } from '../shared/baseApi';
import { createGetRequest, createPutRequest, createPostRequest, createGetRequestWithToken, createPutRequestWithToken } from '../shared/fetch';

export class RestaurantsApi extends BaseApi {
  getFilters() {
    return createGetRequest('restaurants/filters', this._language);
  }

  getList(isDemo) {
    // const searchParams = new URLSearchParams({
    //   search,
    //   types: filters.types.join(','),
    //   dayOfWeek: filters.availableAt.join(',')
    // });
    return createGetRequest(`restaurants/get/${isDemo}`, this._language);
  }

  getRunningToken() {
    return createGetRequest(`getRunningToken`, this._language);
  }

  update(restaurantId, payload) {
    return createPutRequest(`restaurants/${restaurantId}`, JSON.stringify(payload), this._language);
  }

  updateField(restaurantId, payload) {
    return createPutRequest(`restaurants/${restaurantId}/updateRestaurantField`, JSON.stringify(payload), this._language);
  }

  getByCodeByAdmin(restaurantCode) {
    return createGetRequest(`restaurants/byCodeByAdmin/${restaurantCode}`, this._language);
  }

  getByCode(restaurantCode) {
    return createGetRequest(`restaurants/byCode/${restaurantCode}`, this._language);
  }

  createNewRestaurant(payload){
    return createPostRequest(`restaurants/createNewRestaurant`, JSON.stringify(payload), this._language);
  }

  verifyApiKey(restaurantCode, activationKey) {
    const searchParams = new URLSearchParams({
      apiKey: activationKey
    });
    return createGetRequest(`restaurants/byCode/${restaurantCode}/verifyApiKey?${searchParams}`, this._language);
  }

  getRestaurantDataForAdmin(userId, restaurantCode, token) {
    return createGetRequestWithToken(`restaurants/getRestaurantDataForAdmin/${userId}/${restaurantCode}`, this._language, token);
  }

  updateBasicRestaurantForAdmin(restaurantId, payload, token) {
    return createPutRequestWithToken(`restaurants/updateBasicRestaurantAdmin/${restaurantId}`, JSON.stringify(payload), this._language, token);
  }

  fixTranslation(payload) {
    return createPostRequest(`restaurants/fixTranslation`, JSON.stringify(payload), this._language);
  }

  generateDemo(payload) {
    return createPostRequest(`restaurants/copyDemo`, JSON.stringify(payload), this._language);
  }

  askForDemoCode(payload) {
    return createPostRequest(`restaurants/askForDemoCode`, JSON.stringify(payload), this._language);
  }
  
  deleteRunningToken(payload) {
    return createPostRequest(`restaurants/deleteRunningToken`, JSON.stringify(payload), this._language);
  }
  
  generateRunningToken(payload) {
    return createPostRequest(`restaurants/generateRunningToken`, JSON.stringify(payload), this._language);
  }
}