import PictureComponent from "./PictureComponent";
import { useMemo, useEffect } from "react";

function PicturesComponent({ items, OpenModal }) {
  const openModal = (idx) => {
    OpenModal(null, items, idx);
  }

  useEffect(() => {
    window.scrollTo({ 
      top: 0, 
      left: 0, 
      behavior: 'instant'
    })
  }, [])

  const getImages = useMemo(() => {
    return (
      <>
        {!!items && 
        items.map((item, idx) => (
          <PictureComponent key={idx} item={item} idx={idx} OpenModal={openModal} />
        ))
    }
      </>
    ) 
  }, [items])


    return (
      <div className="pictures-style">
        {getImages}
      </div>
    );
  }
  
  export default PicturesComponent;