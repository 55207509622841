import React, { useEffect, useState } from "react";
import { useI18nContext } from "../i18n/context/context";
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import { useParams } from "react-router-dom";
import { ApiLibrary } from '../helpers/api/ApiLibrary';

function VerificationDemoPage({ }) {
  const { language } = useI18nContext();
  const { activationKey } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async() => {
      setIsLoading(true);
      let response = await new ApiLibrary(language).restaurants.general.generateDemo({verificationKey: activationKey, baseUrl: window.location.origin}, language)
      setIsLoading(false);
      if(response.data){
          window.location.href = response.data
      }
      else{
        window.location.href = window.location.origin.concat('/editDemo')
      }
    };
    
    if (activationKey) {
        fetchData();
    }

  }, [activationKey])

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
    </>    
  );
}

export default VerificationDemoPage;
