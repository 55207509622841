import React, { useEffect, useState } from "react";
import { Input, Button, useToaster, Message } from 'rsuite';
import DropzoneDirectiveComponent from "../../../directives/DropzoneDirectiveComponent";
import AdminBasicOpenCloseComponent from "./AdminBasicOpenCloseComponent";
import AdminBasicMapComponent from "./AdminBasicMapComponent";
import { useI18nContext } from "../../../i18n/context/context";
import { ApiLibrary } from "../../../helpers/api/ApiLibrary";
import { useCookies } from 'react-cookie';
import LoaderDirectiveComponent from "../../../directives/LoaderDirectiveComponent";
import ModalEditComponent from "../../modal/ModalEditComponent";
import { HiTranslate } from "react-icons/hi";
import DictionaryEditModalComponent from "../DictionaryEditModalComponent";
import ItemsTextDirectiveComponent from "../../../directives/ItemsTextDirectiveComponent";

function AdminBasicComponent({ restaurant, handleChange, setCheckChangeBookmark, checkChangeBookmark }) {
  const toaster = useToaster();
  const { translate, language } = useI18nContext();
  const [cookies, setCookie, deleteCookie] = useCookies(['sefooToken']);
  const [model, setModel] = useState(null);
  const [isEnabled, setToogleEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dictionary, setDictionary] = useState(null);

  useEffect(() => {
    if(!!restaurant && !model){
      setModel({...restaurant})
    }
  }, [restaurant, model])


  const handleImageChange = async (field, val) => {
    const response = await new ApiLibrary(language).restaurants.general.updateField(restaurant.id, {field: field, value: val})
    if(!response?.data){
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
      return
    }

    setModel(prevModel => { 
      let newModel = {
        ...prevModel, 
        [field]: val
      }
      handleChange(newModel);
      return newModel;
    });
  }

  const setHeaderPhotoUrl = async ([file]) => {
    setIsLoading(true);
    let mainPhotoUrl= null;
    if(file){
      try {
        const response = await new ApiLibrary().common.add(file);
        mainPhotoUrl = response.data?.url;
      } catch (error) {
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      }
    } else {
      try{
        await new ApiLibrary().common.removeByUrl(restaurant?.mainPhotoUrl);
        mainPhotoUrl = null;
      }
      catch (error) {
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      }
    }
    await handleImageChange("mainPhotoUrl", mainPhotoUrl)
    setIsLoading(false);
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("addMediaSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  const setLogoPhotoUrl = async ([file]) => {
    setIsLoading(true);
    let logoPhotoUrl= null;
    if(file){
      try {
        const response = await new ApiLibrary().common.add(file);
        logoPhotoUrl = response.data?.url;
      } catch (error) {
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      }
    } else {
      try{
        await new ApiLibrary().common.removeByUrl(restaurant?.logoPhotoUrl);
        logoPhotoUrl = null;
      }
      catch(error){
        setIsLoading(false);
        toaster.push(
          <Message showIcon type="error" closable>
            {translate("somethingWrong")}
          </Message>, 
          { placement: "topEnd", duration: 5000 }
        )
      }
    }
    await handleImageChange("logoPhotoUrl", logoPhotoUrl)
    setIsLoading(false);
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("addMediaSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  const setLocation = (location) => {
    let thisModel = {...model};
    thisModel.location = {x: location.lng, y: location.lat};
    setModel(thisModel);
    setCheckChangeBookmark(true)
  }

  const onChangeElements = (field, idx, val) => {
    const elements = [...model[field]];
    if(elements[idx] != null) {
      elements[idx] = val;
    } else {
      elements.push(val)
    }
    const updatedModel = {
      ...model,
      [field]: elements.filter(x => !!x)
    }
    setModel(updatedModel);
    setCheckChangeBookmark(true);
  }


  const handleSave = async () => {
    setCheckChangeBookmark(false);
    setIsLoading(true);
    let response = null;
    try{
      response = await new ApiLibrary(language).restaurants.general.update(restaurant.id, model)
    }
    catch(error){
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
    }

    if(!response?.data){
      setIsLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {translate("somethingWrong")}
        </Message>, 
        { placement: "topEnd", duration: 5000 }
      )
      return
    }
    response = await new ApiLibrary(language).restaurants.general.getRestaurantDataForAdmin(cookies['sefooToken']?.userId ?? "editDemo", response.data.restaurantCode, cookies['sefooToken']?.token);
    handleChange(response.data);
    setIsLoading(false);
    toaster.push(
      <Message showIcon type="success" closable>
        {translate("saveChangesSuccess")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  const changeDictionary = async (dictionary, val) => {
    let result = await new ApiLibrary(language).dictionary.changeGlobal({
      restaurantId: model.id,
      dictionaryId: dictionary.id,
      language: language,
      value: val
    })
    
    if(dictionary.items.filter(x => x.lang == language).length > 1){
      let idx = dictionary.items.findIndex(x => x.lang == language && !!x.restaurantId);
      dictionary.items[idx] = result.data;
    }
    else{
      dictionary.items.push(result.data);
    }
    const updatedModel = {
      ...model,
      restaurantType: dictionary
    }

    setModel(updatedModel);
    handleChange(updatedModel);

    toaster.push(
      <Message showIcon type="success" closable>
        {translate("changedTranslation")}
      </Message>, 
      { placement: "topEnd", duration: 5000 }
    )
  }

  return (
    <>
        {isLoading &&
          <LoaderDirectiveComponent />
        }
        {!!dictionary &&
          <ModalEditComponent
            setOpen={() => setDictionary(null)}
            model={dictionary.items?.filter(x => x.lang == language && (!x.restaurantId || x.restaurantId == restaurant.id))?.sort((a, b) => { if (a.restaurantId === null) return 1; if (b.restaurantId === null) return -1; return a - b; })[0].text}
            setModelChanges={(val) => changeDictionary(dictionary, val)}        
            hideLanguages
            isSimpleModel
            hideDeleteButton
            title={translate("translate")}
        >
          <DictionaryEditModalComponent />
        </ModalEditComponent>
        }
      <div className="admin-basic-data-pictures-name-container">
        <div className="margin-top-xl">
            <DropzoneDirectiveComponent onlyPhoto disabled={restaurant.defaultLang != language} onUpload={setHeaderPhotoUrl} item={model?.mainPhotoUrl} containerClassName="restaurant-header-picture" photoClassName="restaurant-header-picture-uploaded" emptyClassName="restaurant-header-picture" />
          </div>
          <div className="display-flex">
            <div className="admin-basic-data-picture-container">
              <DropzoneDirectiveComponent onlyPhoto disabled={restaurant.defaultLang != language} onUpload={setLogoPhotoUrl} item={model?.logoPhotoUrl} className="restaurant-picture" />
            </div>
            <div className="restaurant-name-cointener">
              <div className="restaurant-name-style">
                {restaurant.restaurantName}
              </div>
            </div>
          </div>
      </div>
      {!!model &&
        <div className="admin-basic-main-data-container">
          <div className="display-flex margin-top-xl">
            <div className="admin-basic-label  ">
              {translate("restaurantType")}
            </div>
            <div className="admin-basic-edit-item-field-text  ">
              <div className="admin-basic-items-label  ">
                  {model.restaurantType?.items?.filter(x => x.lang == language && (!x.restaurantId || x.restaurantId == restaurant.id))?.sort((a, b) => { if (a.restaurantId === null) return 1; if (b.restaurantId === null) return -1; return a - b; })[0]?.text} 
                  {restaurant.defaultLang != language && 
                    <span className="translate-button" onClick={() => setDictionary(model.restaurantType)}>
                      <HiTranslate size={16} />
                    </span>
                  }
              </div>
            </div>
          </div>

          <div className="display-flex margin-top-xl">
            <div className="admin-basic-label  ">
              {translate("address")}
            </div>
            <div className="admin-basic-edit-item-field-text  ">
            <div className="admin-basic-items-label  ">
                  {model.address.fullAddress}
              </div>
            </div>
          </div>
        </div>
      }

      <div className={`admin-basic-data-container ${restaurant.defaultLang != language ? "disabled-style" : ""}`}>
      {!! model &&
        <div id="adminBasicDataContainer" className="admin-basic-data-inside-container">
          <div className="">
            <div className="display-flex ">
              <div className="admin-basic-label  ">
                {translate("phones")}
              </div>
              <div className="edit-basic-items-text-field">
                  <ItemsTextDirectiveComponent 
                    placeholder={translate("phone")}
                    items={model?.phones}
                    setItems={(idx, val) => {
                      onChangeElements('phones', idx, val)
                    }}
                  />
              </div>
            </div>

            <div className="display-flex margin-top-xl">
              <div className="admin-basic-label  ">
                {translate("mails")}
              </div>
              <div className="edit-basic-items-text-field">
                  <ItemsTextDirectiveComponent 
                    placeholder={translate("mail")}
                    items={model?.mail}
                    setItems={(idx, val) => {
                      onChangeElements('mail', idx, val)
                    }}
                  />
              </div>
            </div>

            <div className="display-flex margin-top-xl">
              <div className="admin-basic-label  ">
                {translate("restaurantUrls")}
              </div>
              <div className="edit-basic-items-text-field">
                  <ItemsTextDirectiveComponent 
                    placeholder={translate("restaurantUrl")}
                    items={model?.restaurantUrls}
                    setItems={(idx, val) => {
                      onChangeElements('restaurantUrls', idx, val)
                    }}
                  />
              </div>
            </div>

            <div className="display-flex margin-top-xl">
              <div className="admin-basic-label  ">
                {translate("openingHours")}
              </div>
              <div className="edit-basic-items-field  ">
                <AdminBasicOpenCloseComponent restaurant={model} setChanges={(val) => {
                  let thisModel = {...model};
                  thisModel.openCloseInfo = val;
                  setModel(thisModel)
                  setCheckChangeBookmark(true);
                }}/>
              </div>
            </div>

            <div className="edit-basic-map-container">
              <AdminBasicMapComponent isEditable={isEnabled} restaurant={restaurant} setLocation={setLocation}/>
            </div>
          </div>
        </div>
      }
      {!!model && 
        <div className='save-button-container'>
        <Button
          color="green"
          appearance="primary"
          onClick={() => handleSave()}
          disabled={!(model.address.city && model.address.street && model.address.streetNumber) || restaurant.defaultLang != language}
        >
          {translate('save')}
        </Button>
      </div>
      }
      </div>
    </>    
  );
}

export default AdminBasicComponent;
