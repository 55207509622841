import { useEffect } from "react";

function DescriptionComponent({ content }) {

  useEffect(() => {
      window.scrollTo({ 
        top: 0, 
        left: 0, 
        behavior: 'instant'
      })
    }, [])

  return (
    <div className="description-style">
      <div className="padding-left-md padding-right-md ql-editor">
        <div dangerouslySetInnerHTML={{__html: content}} />
      </div>
    </div>
  );
}

export default DescriptionComponent;
