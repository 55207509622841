// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quill-editor-container{
  width: 60%;
  margin: auto;
}

/* @media only screen and (max-width: 1360px){
  .restaurant-start-inside {
    width: 70%;
  }
} */

.QuillEditor {
  display: flex;
  flex-direction: column;
  /* width: var(--posterWidth); */
  margin: 32px auto 0px auto;
  gap: 16px;
  box-shadow: var(--sefooShadow);
  border-radius: 6px;
}

.QuillEditor .disabled {
  opacity: 0.5;
  pointer-events: none;
}

.QuillEditor .quill-editor-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  position: fixed;
  bottom: 55px;
  inset-inline: 0;
}

.ql-container.ql-snow{
  border: none !important;
  border-radius: 0px 0px 6px 6px;
  height: calc(95vh - 290px);
}

.ql-toolbar.ql-snow{
  border: none !important;
  box-shadow: var(--sefooShadow);
  border-radius: 6px 6px 0px 0px;
}

.quill-editor-container img {
  object-fit: contain;
}

@media only screen and (max-width: 1366px){
  .quill-editor-container{
    width: 80%;
  }
}

@media only screen and (max-width: 1000px){
  .quill-editor-container{
    width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/admin/QuillEditor.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;;;;GAIG;;AAEH;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,0BAA0B;EAC1B,SAAS;EACT,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".quill-editor-container{\n  width: 60%;\n  margin: auto;\n}\n\n/* @media only screen and (max-width: 1360px){\n  .restaurant-start-inside {\n    width: 70%;\n  }\n} */\n\n.QuillEditor {\n  display: flex;\n  flex-direction: column;\n  /* width: var(--posterWidth); */\n  margin: 32px auto 0px auto;\n  gap: 16px;\n  box-shadow: var(--sefooShadow);\n  border-radius: 6px;\n}\n\n.QuillEditor .disabled {\n  opacity: 0.5;\n  pointer-events: none;\n}\n\n.QuillEditor .quill-editor-buttons {\n  display: flex;\n  justify-content: flex-end;\n  padding: 16px;\n  position: fixed;\n  bottom: 55px;\n  inset-inline: 0;\n}\n\n.ql-container.ql-snow{\n  border: none !important;\n  border-radius: 0px 0px 6px 6px;\n  height: calc(95vh - 290px);\n}\n\n.ql-toolbar.ql-snow{\n  border: none !important;\n  box-shadow: var(--sefooShadow);\n  border-radius: 6px 6px 0px 0px;\n}\n\n.quill-editor-container img {\n  object-fit: contain;\n}\n\n@media only screen and (max-width: 1366px){\n  .quill-editor-container{\n    width: 80%;\n  }\n}\n\n@media only screen and (max-width: 1000px){\n  .quill-editor-container{\n    width: 95%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
