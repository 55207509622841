import logoPictureSefoo from "../../assets/images/logo/sefoo.svg"

function HomeRestaurantsList({restaurants, setSelectedItem}) {
    return (
        <div className='home-restaurants-list'>
        {restaurants?.map((data, idx) => (
            <div key={idx} className='home-restaurants-list-item' onClick={() => setSelectedItem(data)}>
              <div className="display-flex">
                <div className="home-restaurants-list-item-header">
                    <div className="">
                      {data?.mainPhotoUrl &&
                        <img src={data.mainPhotoUrl} className="restaurant-selected-backgruond-picture" />
                      }
                    </div>
                    <div className="display-flex">
                    {data?.logoPhotoUrl &&
                        <div className={`restaurant-picture-container ${!data.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
                          <img src={data.logoPhotoUrl} className="restaurant-picture" />
                        </div>
                      }
                      {!data?.logoPhotoUrl &&
                        <div className={`restaurant-picture-container ${!data.mainPhotoUrl ? "restaurant-picture-container-without-header" : ""}`}>
                          <img src={logoPictureSefoo} className="restaurant-picture" />
                        </div>
                      }
                        <div className="restaurant-name-cointener">
                            <div className="restaurant-name-style">
                            {data?.restaurantName}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
  
  export default HomeRestaurantsList;
  