import React from "react";
import { Button } from 'rsuite';
import { useI18nContext } from "../i18n/context/context";
import LogoBackComponent from "../components/base/top/LogoBackComponent";
import LanguageComponent from "../components/base/top/LanguageComponent";
import { BsFillPatchCheckFill } from "react-icons/bs";

function SuccessPage({ }) {
  const { translate } = useI18nContext();

  return (
    <>
        <div>
            <div className="col-12 margin-bottom-sx header-flex-style main-header-style">
              <div className="margin-top-sx" >
                <LogoBackComponent />
              </div>
              <div className="display-flex">
              </div>
              <LanguageComponent />
            </div>
          </div>
          <div className="admin-header-underline" />
          <div className="success-page-container">
            <div className="success-page-inside">
              <BsFillPatchCheckFill color="green" size={350} className="success-icon" />
              <div className="success-body-container">
                <div className="success-text-container">
                  {translate('success').toUpperCase()}!
                </div>
                <Button
                    color="green"
                    appearance="primary"
                    onClick={() => {window.location.href = window.location.origin.concat('/login')}}
                    >
                    {translate('goToLoginPage')}
                </Button>
              </div>
            </div>
          </div>
    </>    
  );
}

export default SuccessPage;
