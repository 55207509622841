import React, { useMemo } from "react";

function TestPage({ }) {

  const getHeaderPictures = useMemo(() => {
    return (
      <img src="http://res.cloudinary.com/dyp2lxlnq/image/upload/v1737231196/dishes/luly1zkx6tevldijk8mv.jpg" className="restaurant-selected-backgruond-picture" />
    )
  }, [])

  const getLogoPictures = useMemo(() => {
    return (
      <div className={`restaurant-picture-container `}>
        <img src="http://res.cloudinary.com/dyp2lxlnq/image/upload/v1737231191/dishes/xd0jxidjoqkmsjzbytyc.jpg" className="restaurant-picture" />
      </div>
    )
  }, [])


  return (
    <div className="details-container-style">
      <div className="filter-overflow-auto padding-right-mx">
      <div className="fake-field-mx"></div>
        <div className="">
          {getHeaderPictures}
        </div>
        <div className="display-flex">
          {getLogoPictures}
          <div className="restaurant-name-cointener">
            <div className="restaurant-name-style">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestPage;
